<template >
  <div>
    <div class="coordination-upload">
      <div class="head">上传文件</div>
      <el-form ref="file" :model="file" label-width="80px">
        <i class="neccesary_key"></i>
        <el-form-item label="作品名称" :required="true">
          <el-input v-model="file.name" maxlength="14" :disabled="!flag"></el-input>
        </el-form-item>

        <el-form-item label="上传作者">
          <el-input v-model="file.author" maxlength="14" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="编辑说明">
          <el-input type="textarea" v-model="file.abstract" placeholder="请输入摘要"></el-input>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            :action="uploadURL()"
            :on-success="handleSuccess"
            :limit="1"
            :file-list="fileList"
            :before-upload="beforeupload"
            accept=".doc, .docx"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">(必填)只能上传单个.doc/.docx文件 请等待上传文件完成(出现绿色小勾)</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" v-show="flag">保存</el-button>
          <el-button type="primary" @click="onSubmitByEdited" v-show="!flag">保存</el-button>
          <el-button @click="cancelChange()">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { QUERY, UPDATE, DELETE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      ids: this.$route.query.id,
      file: {
        name: "",
        author: this.$store.state.userinfo.name,
        abstract: "",
        respURL: "",
        showFilePath: "",
        id: ""
      },
      fileList: [],
      flag: true
    };
  },
  watch: {
    $route(to, from) {
      if (to.query.id != from.query.id) {
        if (to.query.id) {
          this.flag = false;
        } else {
          this.flag = true;
          if (!to.query.title) {
            this.file.name = "";
          }
        }
      }
    }
  },
  created() {
    this.file.id = this.$route.query.id;
    this.file.name = this.$route.query.title;
    // console.log("this.$route.query: ", this.$route.query);
    if (Object.keys(this.$route.query).length > 0) {
      // console.log("非空对象");
      this.flag = false;
    } else {
      // console.log("空对象")
      this.flag = true;
    }
    // if(JSON.stringify(this.$route.query) == '{}'){
    //   console.log("空对象");
    // }else{
    //   console.log("非空对象")
    // }
  },
  methods: {
    //取消
    cancelChange() {
      // 取消更改
      if (!this.$route.query.id) {
        this.file.name = "";
      }
      this.file.respURL = "";
      this.file.abstract = "";
      this.fileList = [];
    },
    beforeupload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(testmsg);
      if (testmsg != "docx" && testmsg != "doc") {
        this.$message({
          message: "请上传格式为docx、doc的文件！",
          type: "warning"
        });
        return false;
      }
    },
    async onSubmit() {
      if (this.file.name == "") {
        this.$message.warning("请填写文件名称!");
        return;
      }
      if (this.file.respURL == "") {
        this.$message.warning("请上传文件!");
        return;
      }
      let data = await INSERT(
        "POST",
        "",
        'insert_editTogether(objects:{title:"' +
          this.file.name +
          '",userName: "' +
          this.file.author +
          '", time: "' +
          new Date() +
          '", remark: "' +
          this.file.abstract +
          '", uploadFilePath: "' +
          this.file.respURL +
          '", downFilePath:"' +
          this.file.respURL +
          '", showFilePath:"' +
          this.file.showFilePath +
          '"}) {  affected_rows }'
      );

      if (data.data.insert_editTogether.affected_rows > 0) {
        this.$message({
          message: "上传成功",
          type: "success",
          duration: "1000"
        });
        this.$router.push("coordinationFileList");
      } else {
        this.$message({
          message: "上传失败,请稍后再试",
          type: "error",
          duration: "1000"
        });
      }
    },
    async onSubmitByEdited() {
      if (this.file.respURL == "") {
        this.$message.warning("请上传文件!");
        return;
      }

      let setURL = await UPDATE(
        "POST",
        "",
        "update_editTogether(where:{id:{_eq:" +
          this.file.id +
          '}},_set:{uploadFilePath: "' +
          this.file.respURL +
          '", downFilePath:"' +
          this.file.respURL +
          '", showFilePath:"' +
          this.file.showFilePath +
          '"}) {affected_rows}'
      );

      let data = await INSERT(
        "POST",
        "",
        'insert_editRecord(objects:{editTogetherId:"' +
          this.file.id +
          '",userName: "' +
          this.file.author +
          '", time: "' +
          new Date() +
          '", remark: "' +
          this.file.abstract +
          '", downFilePath: "' +
          this.file.respURL +
          '", showFilePath:"' +
          this.file.showFilePath +
          '"}) {  affected_rows }'
      );

      if (
        data.data.insert_editRecord.affected_rows > 0 &&
        setURL.data.update_editTogether.affected_rows > 0
      ) {
        this.$message({
          message: "编辑后上传成功",
          type: "success",
          duration: "1000"
        });
        this.$router.push({
          path: "/coordinationFileRecord",
          query: { id: this.file.id }
        });
      } else {
        this.$message({
          message: "编辑后上传失败,请稍后再试",
          type: "error",
          duration: "1000"
        });
      }
    },
    handleSuccess(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.file.respURL = file.data.originalFileUrl;
      this.file.showFilePath = file.data.url;
    },
    uploadURL() {
      return this.api.LoginURL.concat("/resources/trading/resourcesUpload");
    }
  }
};
</script>
<style scoped>
.head {
  border-bottom: 1px solid #b5b5b5;
  line-height: 50px;
  margin-bottom: 40px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 800;
}
.label {
  margin-left: -50px;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 50%;
  margin-right: 10px;
}
/* 加入红星 */
.neccesary_key {
  content: "*";
  color: #ff2d52;
  font-size: 14px;
}

.coordination-upload {
  width: 600px;
}
</style>
